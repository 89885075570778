<ng-container *transloco="let translate; read: 'bankFileProcess'">
  <div class="row mb-3 justify-content-between">
    <div class="col-6">
      <h4>Bank File</h4>
    </div>
    <div class="col-auto">
      <app-back-routing-btn [routeTo]="['/DataExportList']" [text]="translate('backToDataExportList')"></app-back-routing-btn>
    </div>
  </div>
  <div class="card ">
    <div class="card-body">
      <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <label for="BankName" class="col-form-label me-2"><span transloco="name"></span></label>
                <input type="text" name="BankNameCtrl" #BankNameCtrl="ngModel" [(ngModel)]="BankName" class="form-control" id="Bank_Name" required>
                <div class="invalid-feedback" *ngIf="BankNameCtrl.errors && BankNameCtrl.errors.required"><span transloco="required"></span></div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-12">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" name="isBankFileActive" [(ngModel)]="isBankFileActive" id="activeBankFileCheck" />
                  <label class="form-check-label" for="activeBankFileCheck">&nbsp;<span transloco="active"></span>&nbsp;</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 d-flex justify-content-end align-items-start">
            <button type="submit" class="btn btn-success" [disabled]="!f.form.valid">
              <i class="fa fa-save"></i>
            </button>
            <button type="button" class="btn btn-primary " [disabled]="isDisabled" (click)="CreateBankFile()" tooltip="{{translate('process')}}">
              <i class="fa fa-hourglass-half"></i>
            </button>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <label for="bankList" class="col-form-label me-2">{{translate('companyBank')}}</label>
                <select id="bankList" name="bankListCtrl" [(ngModel)]="bankId" class="form-control" #bankListCtrl="ngModel"
                        (change)="onSelectedBankChanged()" required>
                  <option *ngFor="let item of bankListArr" [value]="item.i">{{item.n}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="bankListCtrl.errors && bankListCtrl.errors.required">
                  <span transloco="required"></span>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!isUS && !isDisabled">
              <div class="col-12">
                <label for="SeqNum" class="col-form-label me-2">{{translate('nextBankFileSequenceNumber')}}</label>
                <input type="text" name="SeqNumCtrl" #SeqNumCtrl="ngModel" [(ngModel)]="SeqNum" class="form-control" id="Seq_Num">
                <div class="invalid-feedback" *ngIf="f.submitted && SeqNumCtrl.invalid && (SeqNumCtrl.dirty || SeqNumCtrl.touched)">
                  <div *ngIf="SeqNumCtrl.errors.required">{{translate('validations.sequenceNumberIsRequired')}}</div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!isDisabled">
              <div class="col-12">
                <label for="depositDate" class="col-form-label me-2">{{translate('depositDate')}}</label>
                <input type="date" id="depositDate" class="form-control" name="depositDate" [(ngModel)]="depositDate"
                       [attr.data-date]="sysPreference?.df ? (depositDate ? (depositDate | date: sysPreference?.df) : sysPreference?.df) :  depositDate ? fromJsonDate(depositDate) : 'yyyy-mm-dd'  ">
              </div>
            </div>
            <div class="row" *ngIf="isUS && !isDisabled">
              <div class="col-12">
                <label for="include" class="col-form-label me-2">{{translate('include')}}</label>
                <select name="include" [(ngModel)]="include" class="form-select">
                  <option *ngFor="let item of include_list" [value]="item.value">{{translate(item.name)}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row" *ngIf="!isDisabled">
              <div class="col-12">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" name="isAllPGs" [(ngModel)]="isAllPGs" id="activeAllPGs" (change)="valueChange()" />
                  <label class="form-check-label" for="activeAllPGs">&nbsp;{{translate('allPayrollGroups')}}&nbsp;</label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!isDisabled">
              <div class="col-12">
                <label for="PG_select" class="col-form-label">{{translate('payrollGroups')}}</label>
                <kendo-multiselect name="PG_select" class="w-100" id="PG_select" [(ngModel)]="selectedGroups" [data]="payrollGroups" [textField]="'b'"
                                   [valueField]="'a'" style="width:100%" [filterable]="true" [disabled]="isAllPGs" (valueChange)="valueChange()">
                </kendo-multiselect>
              </div>
            </div>
            <div class="row " *ngIf="!isDisabled">
              <div class="col-12">
                <!--<mat-radio-group aria-label="Select an option" class="radio-group" [(ngModel)]="optionType" name="option_Type">
                  <mat-radio-button class="radio-button" *ngFor="let type of optionTypeArr" [value]="type.no">
                    {{type.name}}
                  </mat-radio-button>
                </mat-radio-group>-->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <ng-container>
    <!-- Modal -->
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="fileModal" aria-hidden="true" id="fileModal" data-backdrop="static">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <label id="title">
                {{resultMessage}}
              </label>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <table class="table table-scroll" border="1" rules="rows">
                  <tbody class="tbodyrow1" *ngIf="bankFileList" style="display:table; width:100%">
                    <tr *ngFor="let item of bankFileList; ">
                      <td style="width:100%">{{item.Line}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="fileName" class="col-form-label">{{translate('fileName')}}</label>
                <input type="text" name="fileName" [(ngModel)]="FileName" class="form-control" id="File_Name">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="saveFile()">
              <i class="fas fa-download"></i>{{translate('saveToDownloads')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
