<ng-container *transloco="let translate; read: 'chequePrinting'">
  <div class="k-overlay" *ngIf="opened"></div>
  <div class="row" *ngIf="!wizadmode"><h4 class="col-12">{{translate('title')}}</h4></div>
  <div class="card">
    <div class="card-body">
      <form [formGroup]="parentForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-end align-items-start">
            <button class="btn btn-primary me-2 mb-2" type="submit" [disabled]="parentForm.dirty && parentForm.invalid"><i class="fas fa-refresh"></i></button>
            <button class="btn btn-primary me-2 mb-2" type="button" data-bs-toggle="modal" (click)="onEmailSendClicked()" [disabled]="!employeeSelectedStatus">
              <i class="far fa-envelope"></i>
            </button>
            <button class="btn btn-primary" type="button" [disabled]="!employeeSelectedStatus" (click)="onPreviewButtonClicked()" [hidden]="!!paraForemail">
              <i class="fas fa-print"></i>
            </button>
          </div>
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-4 mb-2">
                <label for="lastChqNum" class="form-label">{{translate('lastChequeNo')}}</label>
                <input type="text" id="lastChqNum" name="lastChqNum" formControlName="lastChequeNumber" class="form-control" readonly />
              </div>
              <div class="col-sm-4 mb-2">
                <label for="startingChqNum" class="form-label">{{translate('startingChequeNo')}}</label>
                <input type="text" id="startingChqNum" name="startingChqNum" formControlName="startingChequeNumber" class="form-control" required>
                <div class="invalid-feedback" *ngIf="(startingChequeNumber.dirty || startingChequeNumber.touched) && startingChequeNumber.invalid">
                  <span *ngIf="startingChequeNumber.errors.required" transloco="required"></span>
                </div>
              </div>
              <div class="col-sm-4 mb-2">
                <label for="payDate" class="form-label">{{translate('paymentDate')}}</label>
                <input type="date" id="payDate" name="payDate"
                       [attr.data-date]="parentForm.controls['paymentDate'].value | date: (sysPref?.df || 'yyyy-MM-dd')" formControlName="paymentDate"
                       class="form-control">
                <div class="invalid-feedback" *ngIf="(paymentDate.dirty || paymentDate.touched) && paymentDate.invalid">
                  <span *ngIf="paymentDate.errors.required" transloco="required"></span>
                </div>
              </div>
              <div class="col-sm-4 mb-2">
                <label for="brdMessage" class="form-label">{{translate('broadcastMessage')}}</label>
                <input type="text" id="brdMessage" name="brdMessage" formControlName="broadcastMessage" class="form-control">
              </div>
              <div class="col-sm-4 mb-2">
                <div class="row">
                  <label class="form-label col-sm-auto">{{translate('payrollGroups')}}</label>
                  <div class="col-sm-auto">
                    <div class="form-check form-switch" tooltip="{{translate('selectAllPayrollGroups')}}" container="body">
                      <input type="checkbox" class="form-check-input" name="isAllPGs" [(ngModel)]="isAllPGs" id="activeAllPGs"
                             (change)="onAllpayrollGrpSwitchClick()" [ngModelOptions]="{standalone: true}" [disabled]="wizadmode" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <kendo-multiselect class="form-control p-0" name="PG_select" id="PG_select" [(ngModel)]="selectedGroups"
                                       [data]="payrollGroups" [textField]="'b'" [valueField]="'a'" style="width:100%" [filterable]="true"
                                       [disabled]="isAllPGs || wizadmode" [ngModelOptions]="{standalone: true}" (valueChange)="valueChange($event)">
                    </kendo-multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <nav ngbNav #nav="ngbNav" class="nav-tabs justify-content-end">
              <ng-container [ngbNavItem]="1">
                <a ngbNavLink><span transloco="details"></span></a>
                <ng-template ngbNavContent>
                  <ng-container *ngTemplateOutlet="detailsTab"></ng-container>
                </ng-template>
              </ng-container>
              <ng-container [ngbNavItem]="2">
                <a ngbNavLink>{{translate('options')}}</a>
                <ng-template ngbNavContent>
                  <ng-container *ngTemplateOutlet="optionsTab"></ng-container>
                </ng-template>
              </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav" class="py-3"></div>
          </div>
        </div>
        <ng-template #detailsTab>
          <app-cheque-printing-details (filterval)="onFiltered($event)"
                                       [employeeList]="getEmployeeChequeList()"
                                       [filteredEmployeeListFromParent]="employeeList"
                                       [parentForm]="parentForm"
                                       [selectedGroups_from_parent]="selectedGroups"
                                       [r_list]="report_list" [imprRptVisible]="input_visible">
          </app-cheque-printing-details>
        </ng-template>
        <ng-template #optionsTab>
          <app-cheque-printing-options [parentForm]="parentForm"></app-cheque-printing-options>
        </ng-template>
      </form>
    </div>
  </div>

  <!--Modal-->
  <kendo-window id="reportModal" class="kwindow-center" *ngIf="opened" (close)="close()" [minWidth]="250" [width]="1000">
    <!--[height]="890"-->
    <kendo-window-titlebar>
      <div class='k-window-title'></div>
      <button kendoWindowCloseAction></button>
    </kendo-window-titlebar>
    <ng-container *ngIf="rprtSrce" style="overflow:auto">
      <tr-viewer #viewer1
                 [containerStyle]="viewerContainerStyle"
                 [serviceUrl]="baseURL"
                 [reportSource]="reportSource"
                 [viewMode]="'INTERACTIVE'"
                 [scaleMode]="'FIT_PAGE_WIDTH'"
                 [scale]="1.0"
                 [sendEmail]="viewerSendEmail"
                 [authenticationToken]="token">
      </tr-viewer>
    </ng-container>
  </kendo-window>
  <!--Modal ends  [sendEmail]="viewerSendEmail" -->
  <!--send email modal starts-->

  <div class="modal fade" id="emailSendModal" tabindex="-1" role="dialog" aria-labelledby="emailSendModalTitle" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <form #sendMailForm="ngForm">
            <div class="container-fluid">
              <div class="row">
                <p><small>{{'chequePrinting.emailsWillBeSent' | transloco: {value: noOfSelectedEmps} }}</small></p>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <label for="subjct">{{translate('subjectOfEmail')}}</label>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8">
                  <input type="text" name="subjct" [(ngModel)]="email_sub" #emlSub="ngModel" id="subjct" class="form-control" required />
                  <div class="invalid-feedback" *ngIf="emlSub.invalid && (emlSub.dirty || emlSub.touched)">
                    <span *ngIf="emlSub.errors.required" transloco="required"></span>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <label for="txtarea">{{translate('bodyOfEmail')}}</label>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8">
                  <textarea id="txtarea" name="txtarea" [(ngModel)]="email_body" class="form-control"></textarea>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-12">
                  <button type="button" class="btn btn-primary me-2" [disabled]="sendMailForm.form.invalid || sendMailForm.form.pristine" (click)="sendEmailOnModalClick(sendMailForm, 1)" data-bs-dismiss="modal">
                    <i class="far fa-envelope"></i>&nbsp;{{translate('sendEmails')}}
                  </button>
                  <button type="button" class="btn btn-warning" data-bs-dismiss="modal">
                    <i class="fas fa-ban"></i>&nbsp;<span transloco="cancel"></span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!--send email modal ends-->
  <!--employees without company email list modal starts-->
  <div class="modal fade" id="empListModal" tabindex="-1" role="dialog" aria-labelledby="empListModalTitle" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <ng-container *ngIf="empWithoutSendEmailEnabled.length > 0">
            <h6>
              {{translate('belowEmployeesNotSelectedReceiveByEmail')}},
            </h6>
            <ul>
              <li *ngFor="let emp of empWithoutSendEmailEnabled">
                {{emp.i}} &nbsp;&nbsp;&nbsp; {{emp.n}}
              </li>
            </ul>
            <hr />
          </ng-container>
          <ng-container *ngIf="empWithoutPassword.length > 0">
            <h6>
              {{translate('belowEmployeesNotProvidedPassword')}}
            </h6>
            <ul>
              <li *ngFor="let emp of empWithoutPassword">
                {{emp.i}} &nbsp;&nbsp;&nbsp; {{emp.n}}
              </li>
            </ul>
            <hr />
          </ng-container>
          <ng-container *ngIf="empWithoutEmailList.length > 0">
            <h6>
              {{translate('belowEmployeesNotSProvidedEmail')}}
            </h6>
            <ul>
              <li *ngFor="let emp of empWithoutEmailList">
                {{emp.i}} &nbsp;&nbsp;&nbsp; {{emp.n}}
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="empWithErrorList.length > 0">
            <h6>
              {{translate('emailCannotBeSentToBelowEmployees')}}
            </h6>
            <ul>
              <li *ngFor="let emp of empWithErrorList">
                {{emp.i}} &nbsp;&nbsp;&nbsp; {{emp.n}}
              </li>
            </ul>
          </ng-container>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"><span transloco="cancel"></span></button>
        </div>
      </div>
    </div>
  </div>
  <!--employees without company email list modal ends-->
</ng-container>
